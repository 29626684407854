<app-navbar-two></app-navbar-two>

<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Contact</h2>

                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Contact</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="contact-box">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-contact-box">
                    <i class="fa fa-map-marker"></i>

                    <div class="content-title">
                        <h3>Address</h3>
                        <p>Irbid, Al Wadi st, 34</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-contact-box">
                    <i class="fa fa-envelope"></i>

                    <div class="content-title">
                        <h3>Email</h3>
                        <a href="mailto:Contact@superdialer.app"
                            >Contact@superdialer.app</a
                        >
                        <a href="mailto:ahmad@superdialer.app"
                            >ahmad@superdialer.app</a
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="contact-area pb-100">
    <div class="container">
        <div class="section-title">
            <h2>Drop us a message for any queries</h2>
            <p>If you have an idea, we would love to hear about it.</p>
        </div>
        <form #dataForm="ngForm" id="dataForm" (ngSubmit)="SendEmail(dataForm)">
        <div class="contact-form">
            <form id="contactForm">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="form-group">
                            <input
                                type="text"
                                name="Name"
                                id="Name"
                                required
                                class="form-control"
                                placeholder="Name"
                                #NameMo="ngModel"
                                [class.error-input]="dataForm.submitted && NameMo.errors "
                                [(ngModel)]="contactUsDetails.Name"
                            />
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <input
                                type="Email"
                                name="Email"
                                id="Email"
                                required
                                class="form-control"
                                placeholder="Email"
                                #EmailMo="ngModel"
                                [class.error-input]="dataForm.submitted && EmailMo.errors "
                                [(ngModel)]="contactUsDetails.Email"
                            />
                        </div>
                    </div>


                    <div class="col-lg-6">
                        <div class="form-group">
                            <input
                                type="text"
                                name="Subject"
                                id="Subject"
                                required
                                class="form-control"
                                placeholder="Subject"
                                [(ngModel)]="contactUsDetails.Subject"
                                #SubjectMo="ngModel"
                                [class.error-input]="dataForm.submitted && SubjectMo.errors "
                            />
                        </div>
                    </div>

                    <div class="col-lg-12">
                        <div class="form-group">
                            <textarea
                                required
                                name="message"
                                class="form-control"
                                cols="30"
                                rows="6"
                                placeholder="Your Message"
                                [(ngModel)]="contactUsDetails.Content"
                                #ContentMo="ngModel"
                                [class.error-input]="dataForm.submitted && ContentMo.errors "
                            ></textarea>
                        </div>
                    </div>

                    <div class="col-lg-12">
                        <button type="submit" form="dataForm" class="default-btn">
                            Send Message
                        </button>
                    </div>
                </div>
            </form>
        </div>
</form>
    </div>
</section>
