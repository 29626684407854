import { Component, OnInit } from '@angular/core';
import {ContactUsModel} from "../../../models/contact-us.model";
import {ContactUsService} from "../../../contact-us.service";
import {ToastrService} from "ngx-toastr";
import {NgForm} from "@angular/forms";

@Component({
  selector: 'app-saas-home',
  templateUrl: './saas-home.component.html',
  styleUrls: ['./saas-home.component.scss']
})
export class SaasHomeComponent implements OnInit {

    contactUsDetails = new ContactUsModel();
    constructor(private contactUsService: ContactUsService, private toastr: ToastrService) { }

    ngOnInit(): void {
    }
    SendEmail(ngForm: NgForm): void {
        if (this.contactUsDetails.Email == null ||  this.contactUsDetails.Content == null ){
            return;
        }
        this.contactUsService.sendEmail(this.contactUsDetails).subscribe({
            next: () => {
                this.contactUsDetails.Email = null;
                this.contactUsDetails.Content = null;
                this.contactUsDetails.Name = null;
                this.contactUsDetails.Subject = null;
                this.toastr.success('Sent Successfully', 'Thank you!');
            },
            error: () => {
                this.toastr.error('Something Went Wrong', 'Please Try Again');
            }
        });
    }
}
