<app-navbar-one></app-navbar-one>

<div class="saas-home-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="hero-content">
                            <span>Super Dialer</span>
                            <h1>
                                Choose The All-in-One Cloud Contact Center
                                Solution
                            </h1>
                            <p>
                                Super Dialer is a communication solution that
                                allows you to erase borders gratns your
                                customers the ability to reach you wherever they
                                are!
                            </p>

                            <div class="saas-btn">
                                <a href="#Contact" class="saas-btn-one"
                                    >Contact Us <i class="fa fa-arrow-right"></i
                                ></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="saas-image rounded">
                            <img
                                class="banner-tab"
                                src="assets/img/dashbaord.png"
                                alt="image"
                            />
                        </div>
                    </div>

                    <div class="circle-img">
                        <img
                            src="assets/img/saas-banner/circle.png"
                            alt="image"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img1">
        <img src="assets/img/shape/shape1.svg" alt="image" />
    </div>
    <div class="shape-img2">
        <img src="assets/img/shape/shape2.svg" alt="image" />
    </div>
    <div class="shape-img3">
        <img src="assets/img/shape/shape3.png" alt="image" />
    </div>
    <div class="shape-img4">
        <img src="assets/img/shape/shape4.png" alt="image" />
    </div>
    <div class="shape-img5">
        <img src="assets/img/shape/shape6.png" alt="image" />
    </div>
</div>

<section class="features-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-12">
                <div class="features-image">
                    <img src="assets/img/IVR.png" alt="image" />

                    <div class="features-shape">
                        <img
                            src="assets/img/features/shape.png"
                            class="wow animate__animated animate__fadeInUp"
                            data-wow-delay="0.6s"
                            alt="image"
                        />
                    </div>

                    <div class="features-arrow">
                        <img
                            src="assets/img/features/features-img2.png"
                            class="wow animate__animated animate__fadeInRight"
                            data-wow-delay="0.6s"
                            alt="image"
                        />
                    </div>
                </div>
            </div>

            <div class="col-lg-5 col-md-12" id="Customizations">
                <div class="features-area-content">
                    <span>Customizations</span>
                    <h2>Customizations you will love!</h2>
                    <p>
                        Our team of professional engineers are ready to tailor
                        the experience for your needs !
                    </p>

                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="features-text bg-ffe7dc">
                                <div class="icon">
                                    <i class="flaticon-lock"></i>
                                </div>
                                <h3>Custom Integrations</h3>
                                <p>
                                    We provide the ability to build custom
                                    integrations with your systems through
                                    different sets of APIs.
                                </p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="features-text bg-f4ffb3">
                                <div class="icon">
                                    <i class="flaticon-claim"></i>
                                </div>
                                <h3>Customizable IVR</h3>
                                <p>
                                    With our fully customizable IVRs, you can
                                    build the IVR flow that works best for your
                                    business.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="features-btn">
                        <a href="#Contact" class="default-btn"
                            >Learn More <i class="fa fa-arrow-right"></i
                        ></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="services-section pb-100" id="Features">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="services-area-content">
                    <span>Features</span>
                    <h3>
                        Unlock the Power of Our Comprehensive Contact Center
                        Features
                    </h3>
                    <p>
                        Experience the difference with our robust array of
                        built-in features that empower your contact center
                        operations. From seamless call routing and intelligent
                        call queuing to detailed analytics and reporting.
                    </p>
                    <p>
                        Our platform provides everything you need to deliver
                        exceptional customer service. Elevate your communication
                        and streamline your customer interactions, all in one
                        place.
                    </p>

                    <div class="services-btn">
                        <a href="#Contact" class="default-btn"
                            >Learn More<i class="fa fa-arrow-right"></i
                        ></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="single-services">
                    <div class="row">
                        <div class="col-lg-6 p-0 col-md-6 col-sm-6">
                            <div class="services-image">
                                <img
                                    src="assets/img/services/services-img1.png"
                                    alt="image"
                                />
                                <h3>Dashboard and Insights</h3>
                            </div>
                        </div>

                        <div class="col-lg-6 p-0 col-md-6 col-sm-6">
                            <div class="services-image">
                                <img
                                    src="assets/img/services/services-img2.png"
                                    alt="image"
                                />
                                <h3>Auto Dialer</h3>
                            </div>
                        </div>

                        <div class="col-lg-6 p-0 col-md-6 col-sm-6">
                            <div class="services-image">
                                <img
                                    src="assets/img/services/services-img3.png"
                                    alt="image"
                                />
                                <h3>Hierarchy Roles</h3>
                            </div>
                        </div>

                        <div class="col-lg-6 p-0 col-md-6 col-sm-6">
                            <div class="services-image">
                                <img
                                    src="assets/img/services/services-img4.png"
                                    alt="image"
                                />
                                <h3>Multi-Language</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="benefit-section pb-70">
    <div class="container">
        <div class="section-title">
            <span> Business Growth </span>
            <h2>Grow your business</h2>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="benefit-area-content">
                    <i class="flaticon-diagram"></i>
                    <h3>Agents KPI's</h3>
                    <p>
                        Our system provides individual KPI's for each agent so
                        you can monitor their performance and enhance it.
                    </p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="benefit-area-content">
                    <i class="flaticon-money"></i>
                    <h3>Automatic Voice Survey</h3>
                    <p>
                        This Feature will help you get feedback from your
                        clients like no other service will do!
                    </p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="benefit-area-content">
                    <i class="flaticon-pen"></i>
                    <h3>Agents Tasks</h3>
                    <p>
                        Wtih our Tasks feature, supervisors can assign tasks to
                        their agents and check on the completed tasks.
                    </p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="benefit-area-content">
                    <i class="flaticon-refresh"></i>
                    <h3>Global Announcements</h3>
                    <p>
                        Global announcements allows admins and supervisors to
                        make an announcement to all logged in agents.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="support-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="support-image">
                    <img src="assets/img/Support.webp" alt="image" />

                    <div class="support-shape">
                        <img
                            src="assets/img/support/support-img2.png"
                            class="wow animate__animated animate__fadeInUp"
                            data-wow-delay="0.6s"
                            alt="image"
                        />
                    </div>

                    <div class="support-arrow">
                        <img
                            src="assets/img/support/support-img3.png"
                            class="wow animate__animated animate__fadeInRight"
                            data-wow-delay="0.6s"
                            alt="image"
                        />
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="support-area-content">
                    <span>Support</span>
                    <h3>Management is easy when getting support properly</h3>
                    <p>
                        We at Super Dialer understand that effective management
                        hinges on receiving exceptional support. Our commitment
                        to delivering top-tier customer support is unwavering.
                        We take pride in offering not just support but perfect
                        support. Our dedicated team of experts is readily
                        available to assist you with any inquiries, technical
                        challenges, or guidance you may require.
                    </p>
                    <p>
                        We prioritize your success and satisfaction, ensuring
                        that you receive the utmost assistance you need to
                        thrive in your endeavors. With [Your Company Name],
                        management becomes a breeze, thanks to our unwavering
                        dedication to providing impeccable support
                    </p>

                    <div class="support-btn">
                        <a href="#Contact" class="default-btn"
                            >Learn More <i class="fa fa-arrow-right"></i
                        ></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="software-section pb-100">
    <div class="container">
        <div class="software-title">
            <h2>Clients Customizations</h2>
        </div>

        <div class="tab software-list-tab">
            <ul class="tabs">
                <li>
                    <a href="#"><i class="flaticon-budget"></i>Integrations</a>
                </li>
                <li>
                    <a href="#"><i class="flaticon-data"></i> Local Numbers</a>
                </li>
                <li>
                    <a href="#"><i class="flaticon-file"></i> View Reports</a>
                </li>
            </ul>

            <div class="tab_content">
                <div class="tabs_item">
                    <div class="tab-image">
                        <img src="assets/img/api.png" alt="image" />

                        <div class="tab-shape">
                            <img
                                src="assets/img/api.png"
                                class="wow animate__animated animate__fadeInUp"
                                data-wow-delay="0.6s"
                                alt="image"
                            />
                        </div>

                        <div class="tab-arrow">
                            <img
                                src="assets/img/software-tab/software-img3.png"
                                class="wow animate__animated animate__fadeInLeft"
                                data-wow-delay="0.6s"
                                alt="image"
                            />
                        </div>

                        <div class="tab-arrow-two">
                            <img
                                src="assets/img/software-tab/software-img3.png"
                                class="wow animate__animated animate__fadeInRight"
                                data-wow-delay="0.6s"
                                alt="image"
                            />
                        </div>
                    </div>
                </div>
                <div class="tabs_item">
                    <div class="tab-image">
                        <img src="assets/img/phone.png" alt="image" />

                        <div class="tab-shape">
                            <img
                                src="assets/img/software-tab/software-img2.png"
                                class="wow animate__animated animate__fadeInUp"
                                data-wow-delay="0.6s"
                                alt="image"
                            />
                        </div>

                        <div class="tab-arrow">
                            <img
                                src="assets/img/software-tab/software-img3.png"
                                class="wow animate__animated animate__fadeInLeft"
                                data-wow-delay="0.6s"
                                alt="image"
                            />
                        </div>

                        <div class="tab-arrow-two">
                            <img
                                src="assets/img/software-tab/software-img3.png"
                                class="wow animate__animated animate__fadeInRight"
                                data-wow-delay="0.6s"
                                alt="image"
                            />
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="tab-image">
                        <img src="assets/img/reports.png" alt="image" />

                        <div class="tab-shape">
                            <img
                                src="assets/img/reports.png"
                                class="wow animate__animated animate__fadeInUp"
                                data-wow-delay="0.6s"
                                alt="image"
                            />
                        </div>

                        <div class="tab-arrow">
                            <img
                                src="assets/img/software-tab/software-img3.png"
                                class="wow animate__animated animate__fadeInLeft"
                                data-wow-delay="0.6s"
                                alt="image"
                            />
                        </div>

                        <div class="tab-arrow-two">
                            <img
                                src="assets/img/software-tab/software-img3.png"
                                class="wow animate__animated animate__fadeInRight"
                                data-wow-delay="0.6s"
                                alt="image"
                            />
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="tab-image">
                        <img
                            src="assets/img/software-tab/software-img1.png"
                            alt="image"
                        />

                        <div class="tab-shape">
                            <img
                                src="assets/img/software-tab/software-img2.png"
                                class="wow animate__animated animate__fadeInUp"
                                data-wow-delay="0.6s"
                                alt="image"
                            />
                        </div>

                        <div class="tab-arrow">
                            <img
                                src="assets/img/software-tab/software-img3.png"
                                class="wow animate__animated animate__fadeInLeft"
                                data-wow-delay="0.6s"
                                alt="image"
                            />
                        </div>

                        <div class="tab-arrow-two">
                            <img
                                src="assets/img/software-tab/software-img3.png"
                                class="wow animate__animated animate__fadeInRight"
                                data-wow-delay="0.6s"
                                alt="image"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="talk-section pb-100" id="Contact">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="talk-form">
                    <form #dataForm="ngForm" id="dataForm" (ngSubmit)="SendEmail(dataForm)">
                        <div class="form-group">
                            <input
                                type="text"
                                name="name"
                                class="form-control"
                                placeholder="What's Your Name"
                                #NameMo="ngModel"
                                [class.error-input]="dataForm.submitted && NameMo.errors "
                                [(ngModel)]="contactUsDetails.Name"
                            />
                        </div>

                        <div class="form-group">
                            <input
                                type="email"
                                name="email"
                                class="form-control"
                                placeholder="Your email address here"
                                #EmailMo="ngModel"
                                [class.error-input]="dataForm.submitted && EmailMo.errors "
                                [(ngModel)]="contactUsDetails.Email"
                            />
                        </div>

                        <div class="form-group">
                            <input
                                type="text"
                                name="subject"
                                class="form-control"
                                placeholder="Subject"
                                [(ngModel)]="contactUsDetails.Subject"
                                #SubjectMo="ngModel"
                                [class.error-input]="dataForm.submitted && SubjectMo.errors "
                            />
                        </div>

                        <div class="form-group">
                            <textarea
                                name="message"
                                class="form-control"
                                cols="30"
                                rows="6"
                                placeholder="Write your text here"
                                [(ngModel)]="contactUsDetails.Content"
                                #ContentMo="ngModel"
                                [class.error-input]="dataForm.submitted && ContentMo.errors "
                            ></textarea>
                        </div>

                        <button type="submit" form="dataForm" class="btn btn-primary">
                            Submit Now
                        </button>
                    </form>
                </div>

                <div class="talk-image">
                    <div class="talk-shape">
                        <img
                            src="assets/img/talk/talk-img1.png"
                            class="wow animate__animated animate__fadeInUp"
                            data-wow-delay="0.6s"
                            alt="image"
                        />
                    </div>

                    <div class="talk-arrow">
                        <img
                            src="assets/img/talk/talk-img2.png"
                            class="wow animate__animated animate__fadeInRight"
                            data-wow-delay="0.6s"
                            alt="image"
                        />
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="talk-content-area">
                    <span>Interested in Super Dialer?</span>
                    <h3>Let’s Talk</h3>
                    <p>
                        We're more than happy to give you more information and
                        to have a meeting with you
                    </p>

                    <div class="talk-info">
                        <p>Email us on</p>
                        <h3>Contact@superdialer.app</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
