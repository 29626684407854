<div class="navbar-area">
    <div class="exto-responsive-nav">
        <div class="container">
            <div class="exto-responsive-menu">
                <div class="logo">
                    <a routerLink="/">
                        <img src="assets/img/logo/logo.png" alt="logo" />
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="exto-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/">
                    <img
                        src="assets/img/logo/logo.png"
                        alt="logo"
                        class="h-30"
                    />
                </a>

                <div class="collapse navbar-collapse mean-menu">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a
                                routerLink="/"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                >Home</a
                            >
                        </li>
                        <li class="nav-item">
                            <a href="#Features" class="nav-link">Features</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#Customizations"
                                >Customizations</a
                            >
                        </li>
                        <li class="nav-item">
                            <a
                                routerLink="/about"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                >About Us</a
                            >
                        </li>
                    </ul>

                    <div class="others-options">
                        <a class="saas-nav-btn" routerLink="/contact"
                            >Contact Us<i class="fa fa-arrow-right"></i
                        ></a>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>
