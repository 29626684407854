<app-navbar-two></app-navbar-two>

<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>About Us</h2>

                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>About Us</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="about-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="circulate-image">
                    <img src="assets/img/onphone.jpg" alt="image" />
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content-area">
                    <span>About us</span>
                    <h3>Circulate every stage of the strategic business</h3>
                    <p>
                        At Super Dialer, we're your trusted partner throughout
                        every phase of establishing a cutting-edge contact
                        center. Our commitment is to guide you seamlessly
                        through the strategic journey, from the initial planning
                        to the final execution, ensuring that your contact
                        center is not just a business function but a strategic
                        asset.
                    </p>
                    <p>
                        We understand that each stage is crucial to your
                        success, and we're dedicated to providing the expertise
                        and solutions you need to achieve your contact center
                        goals. With us, you're never alone – we're with you,
                        every step of the way.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="what-we-do-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="we-do-content-area">
                    <span>What We do</span>
                    <h3>We Tailor the system for your needs</h3>
                    <p>
                        Our dedicated team of experts ensures that the
                        technology you implement isn't just a one-size-fits-all
                        solution, but a tailor-made system that optimally serves
                        your goals. With us, you can expect personalized
                        solutions that seamlessly integrate with your existing
                        processes, providing you with a competitive edge and a
                        technology ecosystem that's designed exclusively for you
                    </p>
                    <p></p>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="we-do-image">
                    <img src="assets/img/onphone2.jpg" alt="image" />
                </div>
            </div>
        </div>
    </div>
</section>

<section class="fun-facts-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span class="odometer" data-count="43214">00</span>
                        <span class="sign-icon"></span>
                    </h3>
                    <p>Completed Calls</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span class="odometer" data-count="9">00</span>
                        <span class="sign-icon">+</span>
                    </h3>
                    <p>Satisfied Clients</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span class="odometer" data-count="9381">00</span>
                        <span class="sign-icon">h</span>
                    </h3>
                    <p>Hours of conversation</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span class="odometer" data-count="6">00</span>
                        <span class="sign-icon"></span>
                    </h3>
                    <p>Team Members</p>
                </div>
            </div>
        </div>
    </div>
</section>
