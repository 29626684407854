import { Component, OnInit } from '@angular/core';
import {ContactUsModel} from '../../../models/contact-us.model';
import {ContactUsService} from '../../../contact-us.service';
import {NgForm} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  contactUsDetails = new ContactUsModel();
  constructor(private contactUsService: ContactUsService, private toastr: ToastrService) { }

  ngOnInit(): void {
  }
  SendEmail(ngForm: NgForm): void {
      if (this.contactUsDetails.Email == null ||  this.contactUsDetails.Content == null ){
          return;
      }
      this.contactUsService.sendEmail(this.contactUsDetails).subscribe({
          next: () => {
              this.contactUsDetails.Email = null;
              this.contactUsDetails.Content = null;
              this.contactUsDetails.Name = null;
              this.contactUsDetails.Subject = null;
              this.toastr.success('Sent Successfully', 'Thank you!');
          },
          error: () => {
              this.toastr.error('Something Went Wrong', 'Please Try Again');
          }
      });
  }
}
