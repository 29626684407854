import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ContactUsService {
    private apiEndpoint = 'https://childlike-lush-operation.glitch.me/sendEmail'; // Replace with your Express API URL

    constructor(private http: HttpClient) {}

    sendEmail(data: any) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.post(this.apiEndpoint, data, { headers });
    }
}
